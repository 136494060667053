import { Flex, Grid } from 'components/box';
import { NotificationImageBox } from 'components/_global/app-notifications';
import { Heading, Text } from 'components/typography';
import { Money } from 'types/types';
import Price from 'components/price';
import Tick from 'icons/notification-tick.svg';

export interface AddedToCartNotificationBodyProps {
  imageUrl: string;
  quantity: number;
  price: Money;
  message?: string;
}

const AddedToCartNotificationBody = ({
  imageUrl,
  quantity,
  price,
  message,
}: AddedToCartNotificationBodyProps) => (
  <Grid width="100%" gridTemplateColumns="1fr auto" alignItems="center">
    <Flex alignItems="center">
      <NotificationImageBox
        boxColor="success"
        boxSvg={Tick}
        imageUrl={imageUrl}
      />
      <Text ml="3" color="darkGrey" fontSize="2">
        +{quantity} added to cart
      </Text>
    </Flex>

    <Heading color="darkGrey" fontSize="2">
      <Price value={quantity * price.value} />
    </Heading>

    {!!message && (
      <Heading
        display="block"
        color="black"
        fontSize="1"
        lineHeight={[1.5, 1.4]}
        gridColumn="1/3"
        pl={1}
      >
        {message}
      </Heading>
    )}
  </Grid>
);

export default AddedToCartNotificationBody;
